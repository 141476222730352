import * as React from 'react';
import { SfDeptCategoryStruct } from '../../../SharedContent/SfDeptCategoryStruct'
import { SfCustomerModel } from '../../../SharedContent/SfCustomerModel'
import { Observer } from '../../../SharedContent/Observer'
import { CartOrderWrapper, CartModel, OrderItem} from '../../../SharedContent/CartModel'
import {ProductCartRequestModel, T_SfCartAction } from '../../../SharedContent/ProductCartRequestModel'
import { SfConfigModel } from "../../../SharedContent/ServiceClass"

export class CartMainComponentReact extends React.Component<any, any>{

    constructor(props: any) {
        super(props);
        this.state = {
            value: null,
            isLoggedIn: false,
            sfCustomer: SfCustomerModel,
            cartData: CartOrderWrapper,
            cartLoaded: false,
            renderCart: false
        };
        this.removeProduct_CLICK = this.removeProduct_CLICK.bind(this);
        this.updatItemQuantity_CLICK = this.updatItemQuantity_CLICK.bind(this);
        this.showCart_CLICK = this.showCart_CLICK.bind(this);

    }
    private static readonly GetCurrentOrderItemsURL = "webservicesCORE2/cartservice/GetCartUiItems";
    private static readonly AddProductToCartUrl = "webservicesCORE2/cartservice/AddProduct";
    private static readonly RemoveFromCartURL_ByPID = "webservicesCORE2/cartservice/RemoveProduct";
    private static readonly UpdateOrderItemURL = "webservicesCORE2/cartservice/UpdateOrderItem";

    private _updateLocalStorage = true;
    private _sourceOfUpdate = 80;

    private _config: SfConfigModel = new SfConfigModel();

    public cartData: CartOrderWrapper = new CartOrderWrapper();
    _sfCart_IsLoading: boolean = false;

    componentDidMount() {
        (window as any).CartMainComponentReact = this;
        this.getCartItemsForState();
        this.initObserverAndSubscribeToSfCustomerSubject();
    }

    showCart_CLICK(suppliedCartModel: CartModel = undefined) {
        if (suppliedCartModel) {
            this.updateCartOrderItemsFromResult(suppliedCartModel, true);
        } else {
            this.setState({
                renderCart: true
            });
            this.getCartItemsForState(true);
        }
    }
    getCartItemsForState(renderCart: boolean = false) {
        if (!this._sfCart_IsLoading) {
            this._sfCart_IsLoading = true;
            var promiseJSON = this.getCartOrderItems();
            promiseJSON.then(result => {
                this.updateCartOrderItemsFromResult(result, renderCart);
                this._sfCart_IsLoading = false;
            })
            .catch((error) => {
                console.error(error);
                this._sfCart_IsLoading = false;
            });
        }
    }

    updateCartOrderItemsFromResult(result: CartModel | any, renderCartInWindow: boolean = false) {
        this.cartData.TotalItemsInCart = result.TotalItemsInCart;
        this.cartData.cartModel = result as CartModel;
        this.setState({
            cartData: this.cartData,
            cartLoaded: true,
            renderCart: renderCartInWindow
        });
                                                                //(count, updateLocalStorage, sourceOfUpdate)
        (window as any).$HeaderMiddleware.CartMiddleware.set_TotalItemsInCart(result.TotalItemsInCart, this._updateLocalStorage, this._sourceOfUpdate);
    }


    _webWorkerPrivate: any = null;
    _webWorkerSet:boolean = false;
    get_WebWorker(){
        if(!this._webWorkerSet){
            if((window as any).Worker){
                this._webWorkerPrivate = new Worker("clientsideapplications/dist/v1/WebWorkerMiddleware.js");
            }

            this._webWorkerSet=true;
        }
        return this._webWorkerPrivate;
    }

    getCartOrderItems(waiforAsyncToComplete: boolean = false) {
        if((window as any).Worker){
        //if(this.get_WebWorker()){
            var self = this;
            var promiseCheckGetCartItems = new Promise(function (resolve, reject) {
                var webWorker =new Worker("clientsideapplications/dist/v1/WebWorkerMiddleware.js"); // self.get_WebWorker();
                var dataMsgForWorker = [
                    "GetDataFromWorker",
                    self._config.GetBaseUrl() + CartMainComponentReact.GetCurrentOrderItemsURL +
                            '?waitForSyncToComplete=' + waiforAsyncToComplete,
                ];
    
                webWorker.onmessage = function (e) {
                    if (e.data) {
                      resolve(e.data);
                        //handleReceiveCheckLoginResponse(e.data);
                    }
                    else {
                        reject();
                    }
                    webWorker.terminate();
                }
                webWorker.postMessage(dataMsgForWorker);
            });
            return promiseCheckGetCartItems;
        }else{

            return fetch(this._config.GetBaseUrl() + CartMainComponentReact.GetCurrentOrderItemsURL +
            '?waitForSyncToComplete=' + waiforAsyncToComplete, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, cors, *same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json", // "Content-Type": "application/x-www-form-urlencoded",
                }
            })
            .then((response) => response.json())
            .then((result) => {
                return result;
            })
            .catch((error) => {
                console.error(error);
                return error;
            });
        }
        

    }
    updatItemQuantity(pid: number, quantity: number) {
        return fetch(this._config.GetBaseUrl() + CartMainComponentReact.UpdateOrderItemURL +
                "?PID=" + pid + "&Quantity=" + quantity, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, cors, *same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json", // "Content-Type": "application/x-www-form-urlencoded",
                }
            })
            .then((response) => response.json())
            .then((result) => {
                return result;
            })
            .catch((error) => {
                console.error(error);
                return error;
            });
    }

    removeProduct_CLICK(prod: OrderItem) {
        if (prod && prod.ProductID != 0) {
            var prodData = new ProductCartRequestModel();
            prodData.PID = prod.ProductID;
            prodData.Action = T_SfCartAction.RemoveProduct as number;
            prodData.Quantity = prod.Quantity;
            (window as any).$HeaderMiddleware.CartMiddleware.removeProductFromCartPromise(prodData)
            .then(result => {
                this.updateCartOrderItemsFromResult(result, true);
                this._sfCart_IsLoading = false;
            });
            //this.RunCartServerUpdate(prodData);
        }
    }

    updatItemQuantity_CLICK(orderItem: OrderItem, increase: boolean) {
        if (orderItem !== undefined && orderItem.ProductID !== 5050) {
            let qtyToSend = 0;
            if (increase) {
                qtyToSend = orderItem.Quantity + 1;
            } else if (orderItem.Quantity > 1) {
                qtyToSend = orderItem.Quantity - 1;
            }
            var prodData = new ProductCartRequestModel();
            prodData.PID = orderItem.ProductID;
            prodData.Action = T_SfCartAction.UpdateProduct as number;
            prodData.Quantity = qtyToSend;
            (window as any).$HeaderMiddleware.CartMiddleware.updatItemQuantityPromise(prodData)
            //this.updatItemQuantity(prodData.PID, prodData.Quantity)
            .then(result => {
                this.updateCartOrderItemsFromResult(result, true);
                this._sfCart_IsLoading = false;
            });
        }
    }

    localObserver: Observer;
    initObserverAndSubscribeToSfCustomerSubject() {
        this.localObserver = new Observer();
        this.localObserver.id = 16;
        this.localObserver.name = ""
        this.localObserver.update = (suppliedContext) => (window as any).CartMainComponentReact.receiveNotification_SfCustomer_CartReact(suppliedContext)
        this.setState({
            stateObserver: this.localObserver
        });
        try {
            setTimeout(function (refObserver) {
                if ((window as any).$SfCustomerSubject !== undefined) {
                    var customSubj = (window as any).$SfCustomerSubject
                    customSubj.addObserver(refObserver);
                }
            }, 50, this.localObserver);

        } catch (exception) {
            console.log(exception);
        }

    }
    receiveNotification_SfCustomer_CartReact(context: SfCustomerModel | any) {
        var self = this;
        setTimeout(function () {
            if (context !== undefined && context.LoggedIn && context.CustomerID !== undefined && context.CustomerID > 0) {
                if (self.state.sfCustomer == null ||
                    (!self.state.sfCustomer.LoggedIn || self.state.sfCustomer.CustomerID !== context.CustomerID)) {
                    self.setState({
                        isLoggedIn: true,
                        sfCustomer: context
                    });

                }

            } else {
                self.setState({
                    isLoggedIn: false,
                    sfCustomer: context
                });

            }

        }, 100);

    }




    //UI Content behavior
    render() {
        if (this.state.renderCart && !this.state.cartLoaded) {
            return this.getPlaceholderContent();
        } else if (this.state.cartLoaded && this.state.renderCart) {
            return this.getCartUiContent();
        }else{
            return this.getEmptyContnet();
        }
        //return this.getPlaceholderContent();
    }
    getEmptyContnet() {
        return <></>;
    }

    private getCartUiContent() {
        var content =
            <div className="h-cart">
                <div className="h-cart__wrapper">
                    {this.getCartHeader(this.state.cartData)}
                    {(this.state.cartData.cartModel.SfCartOrder !== undefined &&
                        this.state.cartData.cartModel.SfCartOrder.OrderItemsDynamicModel !== undefined &&
                        this.getCartBody(this.state.cartData))}

                    {this.getCartFooter(this.state.cartData)}
                </div>
            </div>
        return content;
    }

    private getCartHeader(cartData: CartOrderWrapper) {
        var cartHeader;
        if (cartData.cartModel.SfCartOrder == null ||
            cartData.cartModel.SfCartOrder.PromoCode == null ||
            !cartData.cartModel.SfCartOrder.PromoCode.ShowFreeShipping) {
            cartHeader = <div className="h-cart__header">
                {(cartData.cartModel.TotalItemsInCart > 0 &&
                    (cartData.cartModel.SfCartOrder == null ||
                        cartData.cartModel.SfCartOrder.PromoCode == null ||
                        !cartData.cartModel.SfCartOrder.PromoCode.ShowFreeShipping) &&
                    <p className="h-cart__callout h-cart__callout--free-shipping"
                        dangerouslySetInnerHTML={this.createMarkup(cartData.cartModel.FreeShippingThresholdMessage)}>
                    </p>
                )}
                {(cartData.cartModel.TotalItemsInCart <= 0 &&
                    <p className="h-cart__callout">
                        {cartData.cartModel.Message}
                    </p>
                )}
            </div>
        }
        return cartHeader;
    }
    createMarkup(value: string) {
        return { __html: value };
    }

    private getCartFooter(cartData: CartOrderWrapper) {
        var footerContent =
            <div className="h-cart__footer">
                {(cartData.cartModel.BogoReminderBox && cartData.cartModel.BogoReminderBox.ShowBogoReminderBox &&
                    <span>
                        {cartData.cartModel.BogoReminderBox.BogoReminderBoxMessage}
                    </span>
                )}
                {cartData.cartModel.TotalItemsInCart > 0 &&

                    <div className="h-cart__total">
                        <ul className="h-cart__total-list">
                            <li>
                            <span>{(window as any).$StringResource__HeaderNew_txtItems}</span>
                                {cartData.cartModel.SfCartOrder.SubtotalUiCultureFormatted}
                            </li>
                            <React.Fragment>
                                {this.getCartFooterItems(cartData)}
                            </React.Fragment>

                        </ul>
                    </div>

                }
                <div className="cart-header__button-group">
                    <a href="purchase/cart" className="button">
                        {(window as any).$StringResource__Header_btnViewCart}
                    </a>
                    {(cartData.cartModel.TotalItemsInCart > 0 &&
                        <a href={'login/CheckoutSignInPage'} className="button button--green">
                            {(window as any).$StringResource__GlobalResources_txtCheckout}
                        </a>
                    )}

                </div>

            </div>
        return footerContent;
    }

    private getCartFooterItems(cartData: CartOrderWrapper) {
        var cartFooterItems = [];
        if (cartData.cartModel.SfCartOrder.PromoCode !== undefined &&
            cartData.cartModel.SfCartOrder.PromoCode !== null &&
            cartData.cartModel.SfCartOrder.PromoCode.Discount > 0) {
            if (cartData.cartModel.SfCartOrder.PromoCode.ShowFreeShipping) {
                cartFooterItems.push(
                    <li>
                        <span>
                            {(window as any).$StringResource__GlobalResources_txtDiscount} ("{cartData.cartModel.SfCartOrder.PromoCode.PromoCode}")
                        </span>
                    </li>)
            } else {
                cartFooterItems.push(
                    <li>
                        <span>
                            {(window as any).$StringResource__GlobalResources_txtDiscount} ("{cartData.cartModel.SfCartOrder.PromoCode.PromoCode}")
                        </span>
                        {cartData.cartModel.SfCartOrder.PromoCode.Discount_UIFormatted}
                    </li>)
            }
        }

        var creditsApplied = false;
        if (this.state.isLoggedIn && cartData.cartModel.SfCartOrder.Credits !== undefined &&
            cartData.cartModel.SfCartOrder.Credits !== null &&
            cartData.cartModel.SfCartOrder.Credits.CreditsApplied > 0) {

            creditsApplied = true;
            cartFooterItems.push(
                <li>
                    <span>
                        {(window as any).$StringResource__HeaderNew_txtCreditsApplied}:
                    </span>
                    - {cartData.cartModel.SfCartOrder.Credits.Ui_CreditsApplied}
                </li>);
        }

        if (!cartData.cartModel.SfCartOrder.SfCartShippingUi.HideShippingEntirely) {
            if (cartData.cartModel.SfCartOrder.PromoCode != null &&
                cartData.cartModel.SfCartOrder.PromoCode.ShowFreeShipping &&
                cartData.cartModel.SfCartOrder.SfCartShippingUi.SfCartOrderShipCost == 0) {
                cartFooterItems.push(
                    <li>
                        <span>
                            {cartData.cartModel.SfCartOrder.SfCartShippingUi.ShippingName || (window as any).$StringResource__HelpCenter_txtDelivery}
                        </span>
                        {(window as any).$StringResource__GlobalResources_txtFreeDelivery}
                    </li>);
            } if (cartData.cartModel.SfCartOrder.PromoCode != null &&
                cartData.cartModel.SfCartOrder.PromoCode.ShowFreeShipping &&
                cartData.cartModel.SfCartOrder.SfCartShippingUi.SfCartOrderShipCost > 0) {
                cartFooterItems.push(
                    <li>
                        <span>
                            {cartData.cartModel.SfCartOrder.SfCartShippingUi.ShippingName || (window as any).$StringResource__HelpCenter_txtDelivery}
                        </span>
                        {cartData.cartModel.SfCartOrder.SfCartShippingUi.SfCartOrderShipCostUi}
                    </li>);
            }else {
                cartFooterItems.push(
                    <li>
                        <span>
                            {cartData.cartModel.SfCartOrder.SfCartShippingUi.ShippingName || (window as any).$StringResource__HelpCenter_txtDelivery}
                        </span>
                        {cartData.cartModel.SfCartOrder.SfCartShippingUi.SfCartOrderShipCostUi}
                    </li>);
            }
        }
        //                        ({(window as any).$StringResource__checkout_info_PromotionDiscount} {cartData.cartModel.SfCartOrder.PromoCode.Discount_UIFormatted})
        if (cartData.cartModel.SfCartOrder.TAX_UiCultureFormatted != null) {
            cartFooterItems.push(
                <li>
                    <span>
                        {(window as any).$StringResource__checkout_info_Tax}
                    </span>
                    {cartData.cartModel.SfCartOrder.TAX_UiCultureFormatted}
                </li>);
        }

        if (!creditsApplied) {
            cartFooterItems.push(
                <li className="h-cart__order-total">
                    <span>
                        {(window as any).$StringResource__Mobile_CommonMobiStrings_OrderTotal}
                        </span>
                    {cartData.cartModel.SfCartOrder.TotalUiCultureFormatted}
                </li>);
            
        } else {
            cartFooterItems.push(
                <li className="h-cart__order-total">
                    <span>
                        {(window as any).$StringResource__Mobile_CommonMobiStrings_OrderTotal}
                        </span>
                    {cartData.cartModel.SfCartOrder.Credits.TotalUiCultureFormatted_AfterCredits}
                </li>);
        }
        if (cartData.cartModel.SfCartOrder.SfCartShippingUi.HideShippingEntirely) {
            cartFooterItems.push(
            <li>
                    <i style={{ "fontSize": ".875rem" }}>
                    Shipping options and any applicable taxes will be shown in checkout
               </i>
            </li>
            );
        }

        return cartFooterItems;
    }
    private getCartBody(cartData: CartOrderWrapper) {
        var cartBody =
            <div className="h-cart__body">
                {cartData.cartModel.SfCartOrder.OrderItemsDynamicModel.map(prod =>
                    <div className="h-cart__product">
                        {(prod.ProductID !== 5050 &&
                            <div className="h-cart__product-remove">
                            <button
                                style={{ width: "2.5rem", height: "2.5rem", borderBottomLeftRadius: "100%", display: "block", padding: "0 0 0 0.625rem", boxShadow: "-2px 2px 5px 0px rgba(156,156,156,0.5)", color: "#0a0a0a", fontSize: "1.375rem", background: "#fff", border: 0, cursor: "pointer"}}
                                onClick={() => this.removeProduct_CLICK(prod)}
                                aria-label={(window as any).$StringResource__GlobalResources_txtRemove + " " + prod.Name}>
                                &times;
                            </button>
                            </div>
                        )}
                        {this.getProductWrapper(prod)}
                    </div>
                )}
            </div>
        return cartBody;
    }
    private getProductWrapper(prod: OrderItem) {

        var pidIsBogo = prod.ProductID === 5050;
        var prodWrapper =
            <div className="h-cart__product-wrapper">
                <div className="h-cart__product-image">
                    {(prod.IsBogo &&
                        <span>
                            <img src={this.state.cartData.cartModel.BogoImage} alt="BOGO" />
                        </span>
                    )}
                    {!pidIsBogo &&
                        <a href={'product/detail/' + prod.ProductID + '/' + prod.URL_NAME}>
                        <img src={prod.ImageUrl} alt={(window as any).$StringResource__GlobalResources_txtImageOf.replace('{0}', prod.Name)} />
                        </a>
                    }
                    {pidIsBogo &&
                        <span className="bogoLineItemProductName">
                            <img src={prod.ImageUrl} alt="BOGO" />
                        </span>
                    }

                </div>

                <div className="h-cart__product-info">
                    <div className="h-cart__product-name">
                        {(!pidIsBogo &&
                            <a href={'product/detail/' + prod.ProductID + '/' + prod.URL_NAME}>
                                {prod.Name}
                            </a>
                        )}
                        {(pidIsBogo &&
                            <span className="bogoLineItemProductName">
                                {prod.Name}
                            </span>
                        )}
                    </div>                    
                    <div className="h-cart__product-price">
                        <p className="cart-header__product-price">
                            {(pidIsBogo &&
                                '- ' )}
                            {prod.ConsumerPrice}
                        </p>
                    </div>
                    {(!pidIsBogo &&

                        <div className="h-cart__product-qty">
                        <div className="qty-control">
                            <button className="qty-control__btn qty-control__sub" 
                                onClick={() => this.updatItemQuantity_CLICK(prod, false)}
                                style={{cursor: "pointer"}}
                                aria-label={"- " + (window as any).$StringResource__ProductDetail_lblQty + ": " + prod.Name} >
                                <div className="PlusMinusQ">
                                    <div className="PlusMinusQ__crossbar PlusMinusQ__crossbar--horizontal"></div>
                                </div>
                            </button>

                                <div className="qty-control__number-container">
                                    <div className="qty-control__number">
                                        {prod.Quantity}
                                    </div>
                                </div>

                            <button className="qty-control__btn qty-control__add"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.updatItemQuantity_CLICK(prod, true)} aria-label={"+ " + (window as any).$StringResource__ProductDetail_lblQty + ": " + prod.Name  }>
                                <div className="PlusMinusQ">
                                    <div className="PlusMinusQ__crossbar PlusMinusQ__crossbar--vertical"></div>
                                    <div className="PlusMinusQ__crossbar PlusMinusQ__crossbar--horizontal"></div>
                                </div>
                            </button>


                            </div>

                        </div>

                    )}
                </div>

                {/*<div className="out-of-stock out-of-stock--cart-item out-of-stock--hc">
                    <div className="out-of-stock__icon">
                        <img src="webcontent/icons/baseline-error_outline-24px--cc4b37.svg" />
                    </div>
                    <div className="out-of-stock__message">
                        <p>This product is temporarily out of stock and is expected to dispatch within <span>2 Working Days</span>. If you are ordering any additional items, this product will be dispatched separately at no extra charge.</p>
                    </div>
                </div>*/}


            </div>
        return prodWrapper;
    }

    private getPlaceholderContent() {
        return <div className="cart-placeholder">
            <div className="cart-placeholder__callout"></div>
            <div className="product-placeholder">
                <div className="proudct-placeholder__item">
                    <div className="product-placeholder__image"></div>
                    <div className="product-placeholder__info">
                        <div className="product-placeholder__title"></div>
                        <div className="product-placeholder__price"></div>
                        <div className="product-placeholder__qty">
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="product-placeholder">
                <div className="proudct-placeholder__item">
                    <div className="product-placeholder__image"></div>
                    <div className="product-placeholder__info">
                        <div className="product-placeholder__title"></div>
                        <div className="product-placeholder__price"></div>
                        <div className="product-placeholder__qty">
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cart-placeholder__footer">
                <div className="cart-placeholder__subtotal"></div>
                <div className="cart-placeholder__buttons">
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    }



    //End UI Content behavior
}

